<template>
  <v-container>
    <header-view :title="'Selecione o serviço'" class="mb-8" />

    <v-row>
      <v-col
        v-for="(item, i) in services"
        cols="6"
        sm="4"
        md="3"
        lg="2"
        :key="i"
      >
        <v-card
          color="surface"
          class="rounded-lg zoom"
          @click="submit(item.value)"
        >
          <section class="accent rounded-b-0">
            <v-img
              :src="require(`@/assets/${item.value}/logo.png`)"
              :aspect-ratio="4 / 4"
            />
          </section>

          <section class="py-3 px-4">
            <div class="text-body-1 font-weight-bold overtext--text">
              {{ item.name }}
            </div>
          </section>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "Home",

  data() {
    return {
      services: [
        {
          name: "Arena Jogue Fácil",
          value: "arena",
        },
        {
          name: "Liga Estudantil",
          value: "league",
        },
      ],
    };
  },

  computed: {
    ...mapState(["service"]),
  },

  beforeMount() {
    if (!!this.service) this.setService("");
  },

  methods: {
    ...mapActions(["setService"]),

    submit(value) {
      this.setService(value);
      this.$router.push({ path: "/" + value });
    },
  },
};
</script>

<style></style>
